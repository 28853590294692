import './Footer.css'
import { Link } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import FooterNav from './data/FooterNav.json'

const FooterMenu = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 4,
        rowGap: 5,
        gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, auto)', lg: 'repeat(6, auto)' },
        borderColor: '#333',
        borderStyle: 'solid',
        borderWidth: '1px 0',
        py: 4
      }}
    >
      {FooterNav.map((navigation, index) => (
        <Stack key={index} sx={{ textAlign: 'left', gap: 1 }}>
          <Typography variant='h6' sx={{ fontWeight: 700 }}>
            <FormattedMessage id={navigation.title} />
          </Typography>
          <Stack>
            {navigation.dropdown.map((subnav, subIndex) => (
              <Link
                to={subnav.url}
                key={subIndex}
                className='footer-link'
                data-gtm-click-footer-link={subnav.gtmData}
                target={subnav.blank ? '_blank' : undefined}
                rel={subnav.blank ? 'noopener noreferrer' : undefined}
                style={{ fontSize: 15 }}
              >
                <FormattedMessage id={subnav.title} />
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
    </Box>
  )
}

export default FooterMenu
