import React from 'react'
import { Box, IconButton, Stack } from '@mui/material'
import { Facebook, Instagram, YouTube, LinkedIn } from '@mui/icons-material'
import { BsTwitterX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Region from '../Region'
import { GVIconG } from 'src/constants'
import FooterSocialNav from './data/FooterSocialNav.json'

const Twitter = () => <BsTwitterX />

const iconMap: Record<string, React.ElementType> = {
  YouTube,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn
}

export default function FooterSocial() {
  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: 3,
        pb: 4
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <IconButton component={Link} to='/' data-gtm-click-footer-icon='Gemvision'>
          <Box component='img' sx={{ content: `url(${GVIconG})`, width: 24 }} alt='Gemvision - A Stuller Company' />
        </IconButton>
        <Region />
      </Stack>

      <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 1 }}>
        {FooterSocialNav.map((subnav, index) => {
          const IconComponent = iconMap[subnav.icon]
          return (
            <IconButton
              key={index}
              data-gtm-click-footer-icon={subnav.gtmData}
              size='small'
              disableRipple
              href={subnav.url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconComponent />
            </IconButton>
          )
        })}
      </Stack>
    </Stack>
  )
}
