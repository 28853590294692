import './Footer.css'
import { Link } from 'react-router-dom'
import { Typography, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import FooterLegalNav from './data/FooterLegalNav.json'

export default function FooterLegal() {
  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        spacing: 1,
        pt: 5,
        gap: 3
      }}
    >
      <Stack direction='row' alignItems='center' columnGap={2} rowGap={0.5} flexWrap='wrap' justifyContent='center'>
        {FooterLegalNav.map((navigation, subIndex) => (
          <Typography variant='body2' key={subIndex}>
            <Link to={navigation.url} className='footer-link' data-gtm-click-footer-link={navigation.gtmData}>
              <FormattedMessage id={navigation.title} />
            </Link>
          </Typography>
        ))}
      </Stack>
      <Typography variant='body2'>
        <FormattedMessage id='legal.copyrightGemvisionAllRightsReserved' values={{ year: new Date().getFullYear() }} />
      </Typography>
    </Stack>
  )
}
