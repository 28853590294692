import { Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import FooterSocial from './FooterSocial'
import FooterMenu from './FooterMenu'
import FooterMenuMobile from './FooterMenuMobile'
import FooterLegal from './FooterLegal'

export default function Footer() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box component='footer' sx={{ bgcolor: '#111' }}>
      <Stack sx={{ py: 7, px: { xs: 2, sm: 6 }, maxWidth: 1600, mx: 'auto' }}>
        <FooterSocial />
        {isSmallScreen ? <FooterMenuMobile /> : <FooterMenu />}
        <FooterLegal />
      </Stack>
    </Box>
  )
}
