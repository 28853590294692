import React from 'react'
import './Footer.css'
import { styled } from '@mui/material/styles'
import { AccordionDetails, AccordionSummary, Accordion, Typography, Stack, Box } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import FooterNav from './data/FooterNav.json'

const AccordionPanel = styled(Accordion)({
  textAlign: 'left',
  width: '100%',
  maxWidth: 1080,
  margin: '0 !important' /* To avoid movement while expanding */,
  background: 'transparent',
  boxShadow: 'none',
  border: 'none',
  borderBottom: '1px solid #333',
  borderRadius: 0,
  padding: 0,
  '&:first-of-type': {
    borderTop: '1px solid #333'
  }
})

const AccordionPanelSummary = styled(AccordionSummary)({
  padding: 0,
  minHeight: '54px !important',
  '&.Mui-expanded': {
    minHeight: '54px !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0 !important'
  }
})

const AccordionPanelDetails = styled(AccordionDetails)({
  display: 'block',
  padding: 0,
  paddingBottom: '1rem'
})

const FooterMenuMobile = () => {
  const [expanded, setExpanded] = React.useState('')
  const handleChange = (newExpanded: string) => setExpanded(newExpanded !== expanded ? newExpanded : '')

  return (
    <Box>
      {FooterNav.map((navigation, index) => (
        <AccordionPanel
          key={`panel-${index}`}
          expanded={expanded === `panel${index}`}
          onChange={() => handleChange(`panel${index}`)}
        >
          <AccordionPanelSummary
            expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
          >
            <Typography variant='h6' sx={{ fontWeight: 700 }}>
              <FormattedMessage id={navigation.title} />
            </Typography>
          </AccordionPanelSummary>
          <AccordionPanelDetails>
            <Stack sx={{ py: 0.5 }}>
              {navigation.dropdown.map((subnav, subIndex) => (
                <Link
                  key={`subnav-${index}-${subIndex}`}
                  to={subnav.url}
                  target={subnav.blank ? '_blank' : undefined}
                  rel={subnav.blank ? 'noopener noreferrer' : undefined}
                  data-gtm-click-footer-link={subnav.gtmData}
                  className='footer-link'
                  style={{ fontSize: 15 }}
                >
                  <FormattedMessage id={subnav.title} />
                </Link>
              ))}
            </Stack>
          </AccordionPanelDetails>
        </AccordionPanel>
      ))}
    </Box>
  )
}

export default FooterMenuMobile
